/* styles.css */
.pageBase {
    height: calc(100vh - 60px);
    /* overflow-y: auto; */
}

.sideMenu {
    position: absolute;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: auto;
}

.wrapText {
    white-space: normal !important;
    /* 允許文字自動換行 */
    word-wrap: break-word !important;
    /* 在必要時打斷長字串 */
    line-height: 2;
    /* 增加行高讓文字顯示更清楚 */
}