/* Overlay.css */
.loading-overlay {
    position: fixed;
    /* 固定定位，使遮罩層始終覆蓋整個視窗 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    display: flex;
    justify-content: center;
    /* 水平居中 */
    align-items: center;
    /* 垂直居中 */
    z-index: 1000;
    /* 確保遮罩層位於最上層 */
}

.loading-image {
    width: 100px;
    /* 或根據需要調整 GIF 的尺寸 */
    height: 100px;
    /* 根據 GIF 實際尺寸調整 */
}
/* styles.css */
.pageBase {
    height: calc(100vh - 60px);
    /* overflow-y: auto; */
}

.sideMenu {
    position: absolute;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: auto;
}

.wrapText {
    white-space: normal !important;
    /* 允許文字自動換行 */
    word-wrap: break-word !important;
    /* 在必要時打斷長字串 */
    line-height: 2;
    /* 增加行高讓文字顯示更清楚 */
}
